.input-item-label {
  font-size: 12px;
  margin-bottom: 7px;
}

.date-range {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-toggle {
  text-align: end;
  line-height: 1.5;
}

.filter-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .submit-button {
    button {
      min-width: 82px;
    }
  }

  .clear-button {
    button {
      min-width: 82px;
    }
  }
}
