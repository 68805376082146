span.error {
  color: #ff6868;
  position: relative;
  //top: 7px;
}
.uploading-div {
  display: flex;
  padding-top: 20px;
}
.mobile-with-Country-code {
  display: flex;
  gap: 10px;

  .input-item:first-child {
    flex-basis: 100px;

    span.error {
      white-space: nowrap;
    }
  }

  div {
    flex-basis: 100%;
  }
}