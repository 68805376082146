.ImageCapture {
    .image-preview {
        button {
            margin-top: 9px;
        }
    }
    .camera-holder {
        &.hidden {
            display: none;
        }
    }
}