.ICODistributionPage {
  .ico-schedule {
    .list {
      display: flex;
      div {
        width: 100px;
      }
    }
  }
}
