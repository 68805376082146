/* input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
} */

/* Firefox */
/* input[type=number] {
    -moz-appearance: textfield;
} */

.input-with-hint {
    padding-right: 50px;
}