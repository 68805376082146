.btn {
  &.kyc-status {
    margin-bottom: 0;
    height: 60px;
    &.success {
      background-color: var(--success);
    }
    &.warning {
      background-color: var(--warning);
    }
    &.info {
      background-color: var(--cyan);
    }
    &.warning {
      background-color: var(--danger);
    }
  }
  &.buy-now {
    margin-bottom: 0;
    height: 60px;
    background-color: #dfb758;
  }
}
