.loader {
  position: absolute;
  top: 100px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  background: rgba(224, 232, 243, 0.4);
  z-index: 1;
}
